import React from 'react';

export default function Contact() {
    
        return (
            <div className='contact'>

            </div>
        );
    }
