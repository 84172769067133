import React from 'react'

export default function Maintence()  {

    return (
      <div className='center'>
        hola
      </div>
    )
  
}
