import React from "react";
import { Link } from 'react-router-dom';
import SignupForm from '../form/Form'
import BottomBanner from "../bottomBanner";
// import { useNavigate } from 'react-router-dom'
// import Contact from "./contact";
export default function HomePage() {
//   const navigate = useNavigate();
//   const navigateProps = (path) => {
//   navigate(path);
// }
    return (
    <div>
      {/* <div className="superior">
        <div className='b'>
          <img src="https://watercrafters.com/wp-content/uploads/2023/12/logo.svg" alt='logo'></img>
        </div>
        <div className="call">
        <span className="textC">CALL US TODAY!</span>
        <span className="number">633-610-777</span>
        </div>
      </div> */}
      <div className='contact'>
            <span></span>
            <div className='textoC'>
                <span>Revitalize Your Pool with Watercrafters' Expert Renovation Services - Where Quality Meets Innovation!</span>
                <Link className='button' to={'/Contact'}> contact us </Link>
                
            </div>

            <img src="https://watercrafters.com/wp-content/uploads/2023/04/renopic3-1024x573.jpg" alt="img"></img>
            <span></span>
      </div>
      <div className="renovationForm">
    
        <div className="renovationInfo">
            <h2>Pool Renovations</h2>
            <span className="textoD">Our pool renovations team specializes in tile, coping, caulking, plaster, plaster prep, tile, skimmer repairs, deck work, vinyl liner replacements and Loop-Loc safety covers.
            Let us assist you in transforming your backyard into a serene oasis for relaxation.
            <br></br>
            <br></br>
            We offer free onsite consultation with our expert renovations team. Third party financing is also available.
            <br></br>
            <br></br>
            Enhancing the appearance of your pool isn’t the only reason to          give it an update. Watercrafters makes sure that your pool is           safe by replacing old fixtures and plumbing, installing pool        lighting and safety covers, and solving any drainage issues you      may be experiencing.
            <br></br>
            <br></br>
            Rejuvenate your pool with Watercrafters and give us a call today! 

            </span>
        </div>

        <div className="renovationF">
            <SignupForm></SignupForm>
        </div>
      </div>
        <BottomBanner></BottomBanner>
 
      <script src="scroll.js"></script>
    </div>
    )
  }
