/* eslint-disable */
// import logo from './logo.svg';
import './index.css'
import './App.css';
// import HomePage from './pages/HomePage';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Pool from './pages/pool';
import Maintence from './pages/Maintence';
import Contact from './pages/contact';
import Renovation from './pages/Renovation'
import './pages/scroll'
import logoRicardo from './imagenes/logoRicardo.jpg'

function App() {
  return (
    <Router>
      <div className="superior">
        <div className='b'>
        <a  href="https://e00-elmundo.uecdn.es/assets/multimedia/imagenes/2024/05/07/17150731876265.jpg" target="_blank"><img className='img' src={logoRicardo} alt='logo'></img>
</a>
        </div>
        <div className="call">
        <span className="textC">CALL US TODAY!</span>
        <span className="number">633-610-777</span>
        </div>
      </div>
 {/* !!!!!!!ESTE ES EL TOP BANNER Y LO USAREMOS CUANDO TENGAMOS VARIAS PAGINAS HECHAS */}
      <div className='top-banner' id='banner'>
      <div className='b'>
        {/* <Link className='Links' to={'/Pool'}> Pool </Link> */}
      </div>
      <div className='b'>
        <Link className='Links' to={'/Maintence'}> Maintence </Link>
      </div>
      <div className='b'>
      <Link className='Links' to={'/'}> Renovation </Link>
      </div>
      <div className='b'>
      <Link className='Links' to={'/Contact'}> Contact </Link>
      </div>
      <script src="script.js"></script>
    </div>


      <Routes>
        {/* <Route exact path='/' element={<HomePage/>}></Route> */}
        <Route path='/Pool' element={<Pool/>}></Route>
        <Route path='/Maintence' element={<Maintence/>}></Route>
        <Route path='/Contact' element={<Contact/>}></Route>
        <Route exact path='/' element={<Renovation/>}></Route>

      </Routes>
    </Router>
  );
}

export default App;
