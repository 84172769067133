import React from "react";
import { Formik, Form, Field,  } from "formik";
import * as Yup from "yup";
import "../form/FormStyle.css";

// Esquema de validación con Yup
const SignupSchema = Yup.object().shape({
  FullName: Yup.string()
    .min(2, "Too Short!")
    .max(70, "Too Long!")
    .required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  phone: Yup.string()
  .min(7,"It must have 7 digits")
  .required("Required"),
  address: Yup.string()
  .min(2,"Too Short!")
  .max(100, "Too Long!")
  .required("Required"),
  city: Yup.string()
  .min(2, "To Short!")
  .max(50, "To Long!")
  .required("Required")
});

export default function App() {
  return (
    <div>
      <Formik
        initialValues={{
          FullName: "",
          email: "",
          address:"",
          city:""
        }}
        validationSchema={SignupSchema}
        onSubmit={(values) => {
          // Manejo de envío de formulario
          alert(JSON.stringify(values, 2));
        }}
      >
        {({ errors, touched }) => (
          <Form className="customForm formik">
            {/* Campo FullName */}
            {/* <label htmlFor="FullName">Name</label> */}
            <Field id="FullName" name="FullName" type="text" placeholder="Name*" />
            {errors.FullName && touched.FullName && (
              <div style={{ color: "red" }}>{errors.FullName}</div>
            )}
            <div className="formikl">
            {/* Campo phone */}
            {/* <label htmlFor="phone">phone</label> */}
            <Field id="phone" name="phone" type="tel" placeholder="Phone*"/>
            {errors.phone && touched.phone && (
              <div style={{ color: "red" }}>{errors.phone}</div>
            )}
            {/* Campo email */}
            {/* <label htmlFor="email">Email Address</label> */}
            <Field id="email" name="email" type="email" placeholder="Email*"/>
            {errors.email && touched.email && (
              <div style={{ color: "red" }}>{errors.email}</div>
            )}
            </div>

            {/* Campo address */}
            {/* <label htmlFor="addres">addres</label> */}
            <Field id="address" name="address" type="text" placeholder="address*"/>
            {errors.address && touched.address && (
              <div style={{ color: "red" }}>{errors.address}</div>
            )}

            {/* Campo city */}
            {/* <label htmlFor="city">city</label> */}
            <Field id="city" name="city" type="text" placeholder="city*"/>
            {errors.city && touched.city && (
              <div style={{ color: "red" }}>{errors.city}</div>
            )}

            {/* Campo zip */}
            {/* <label htmlFor="zip">city</label> */}
            <Field id="zip" name="zip" type="text" placeholder="zip*"/>
            {errors.zip && touched.zip && (
              <div style={{ color: "red" }}>{errors.zip}</div>
            )}
            
            {/* Botón de envío */}
            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
