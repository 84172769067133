// Detectamos cuando se hace scroll
window.onscroll = function() {
    let banner = document.getElementById("banner");
  
    // Si el scroll supera la posición inicial del banner (100px), hacemos que se quede fijo en la parte superior
    if (window.scrollY >= 190) {
      banner.style.position = 'fixed';
      banner.style.top = '0';  // El banner se pega al top cuando el scroll supera los 100px
    } else {
      banner.style.position = 'absolute';  // Si no ha superado el scroll, el banner vuelve a su posición original
      banner.style.top = '190px';  // Mantiene la posición inicial
    }
  };